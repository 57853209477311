import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
export default function ({ children }) {
  return (
    <>
      <ThemeProvider theme={saasTheme}>
        <Helmet>
          <meta
            name="apple-itunes-app"
            content="app-clip-bundle-id=pl.identt.VERIFYeID.Clip"
          ></meta>
        </Helmet>
        {children}
      </ThemeProvider>
    </>
  );
}
